import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { Copyright } from './CopyrightComponent'
import Box from '@mui/material/Box'
import React, { useContext, useState } from 'react'
import API from '../api/API'
import { AlertContext } from '../error/AlertProvider'

export const ChangePassword = () => {
  const [oldPasswordValid, setOldPasswordValid] = useState<boolean>(true)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [validatePassword, setValidatePassword] = useState('')
  const { setMessage } = useContext(AlertContext)

  const passwordsMatch = newPassword == validatePassword || validatePassword === ''

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    console.log('old,', oldPassword, 'new', newPassword, 'vali', validatePassword)
    if (passwordsMatch) {
      API.changePassword(oldPassword, newPassword)
        .then(resp => {
          setNewPassword('')
          setOldPassword('')
          setValidatePassword('')
          console.log(resp.message)
          setMessage('Password changed successfully', 'success')
        })
        .catch(e => {
          setMessage(e.message, 'error')
          if (e.status === 409) {
            setOldPasswordValid(false)
            setOldPassword('')
          }
        })
    }
  }
  return (
    <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <TextField
          error={!oldPasswordValid}
          required
          fullWidth
          name='old-password'
          label='Old Password'
          type='password'
          id='old-password'
          autoComplete='old-password'
          value={oldPassword}
          onChange={event => setOldPassword(event.target.value)}
          helperText={oldPasswordValid ? '' : 'Old Password is not Valid!'}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          // error={newPassword === ''}
          required
          fullWidth
          name='new-password'
          label='New Password'
          type='password'
          id='new-password'
          autoComplete='new-password'
          value={newPassword}
          onChange={event => setNewPassword(event.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          error={!passwordsMatch}
          required
          fullWidth
          name='controllPassword'
          label='Repeat Password'
          type='password'
          id='controll-password'
          autoComplete='new-password'
          value={validatePassword}
          onChange={event => setValidatePassword(event.target.value)}
          helperText={passwordsMatch ? '' : `Passwords don't match`}
        />
      </Grid>
      <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
        Change Password
      </Button>

      <Copyright sx={{ mt: 5 }} />
    </Box>
  )
}
