import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Copyright } from './CopyrightComponent'
import Api from '../api/API'
import { AlertContext } from '../error/AlertProvider'

// TODO remove, this demo shouldn't need to reset the theme.

export const SignIn = () => {
  const [logged, setLogged] = useState(Api.isLogged())
  const { setMessage } = useContext(AlertContext)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  useEffect(() => {
    if (Api.isLogged()) {
      setLogged(true)
    }
  }, [logged])

  const navigate = useNavigate()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const logindata: { username: string; password: string } = {
      username: username,
      password: password,
    }
    if (!forgotPassword) {
      Api.login(logindata)
        .then(() => {
          navigate('/', { replace: true })
        })
        .catch(err => {
          setMessage(err.message, 'error')
        })
    } else {
      Api.sendResetEmail(username)
        .then(() => {
          navigate('/', { replace: true })
          setMessage('Email sucessfully sent', 'success')
        })
        .catch(err => {
          setMessage(err.message, 'error')
        })
    }
  }

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url('background.png')`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'white',
          backgroundSize: 'contain',
          backgroundPosition: 'left',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            {forgotPassword ? 'Forgot Password? ' : 'Sign in'}
          </Typography>
          <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='username'
              label='Email Address'
              name='username'
              autoComplete='username'
              autoFocus
              value={username}
              onChange={event => setUsername(event.target.value)}
            />
            {!forgotPassword && (
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                value={password}
                onChange={event => setPassword(event.target.value)}
                autoComplete='current-password'
              />
            )}

            <FormControlLabel control={<Checkbox value='remember' color='primary' />} label='Remember me' />
            {forgotPassword && (
              <Grid item xs>
                <Link href='#' variant='body2' onClick={() => setForgotPassword(false)}>
                  Back to login
                </Link>
              </Grid>
            )}
            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
              {forgotPassword ? 'Send' : 'Sign in'}
            </Button>
            <Grid container>
              {!forgotPassword && (
                <Grid item xs>
                  <Button href='#' variant='text' onClick={() => setForgotPassword(true)}>
                    Forgot password?
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button variant='text' onClick={() => navigate('/signup', { replace: true })}>
                  {"Don't have an account? Sign Up"}
                </Button>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
