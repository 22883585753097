import React, { useEffect } from 'react'
import './App.css'
import { SignIn } from './auth/SingInComponent'
import { SignUp } from './auth/SignUpComponent'
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import RequireAuth from './auth/RequireAuth'
import { Users } from './users/Users'
import { HomePage } from './components/Home'
import API from './api/API'
import { AlertProvider } from './error/AlertProvider'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const defaultTheme = createTheme({
  palette: {
    primary: { light: '#ff7961', main: '#A50E0E', dark: '#ba000d', contrastText: '#ffff' },
  },
})

function App() {
  const [queryParams] = useSearchParams()
  const email = queryParams.get('email')
  const code = queryParams.get('code')

  return (
    <div className='App'>
      <ThemeProvider theme={defaultTheme}>
        <AlertProvider>
          <Routes>
            <Route path='/' element={<RequireAuth ele={<HomePage />} />}></Route>
            <Route path='/users' element={<RequireAuth ele={<Users />} />}></Route>
            <Route path='/signup' element={<SignUp />} />
            <Route path='/signin' element={<SignIn />} />
            {code != null && <Route path='/password' element={<SignUp email={email} code={code} />} />}
            <Route path='/*' element={<Navigate to='/' replace />} />
          </Routes>
        </AlertProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
