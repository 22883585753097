import React, { createContext, PropsWithChildren, useState } from 'react'
import { Alert, AlertColor } from '@mui/material'

interface IErrorContext {
  setMessage(e: string, severity: AlertColor): void
}

export const AlertContext: React.Context<IErrorContext> = createContext({
  setMessage: (e: string, severity: AlertColor) => {
    console.log(e, severity)
  },
})

export const AlertProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [error, setError] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('error')
  const [isVisible, setVisible] = useState(false)
  const setErrorAlert = (e: string, severity: AlertColor) => {
    setError(e)
    setSeverity(severity)
    setVisible(true)
  }

  return (
    <AlertContext.Provider value={{ setMessage: setErrorAlert }}>
      {isVisible && (
        <Alert
          style={{
            position: 'sticky',
          }}
          severity={severity}
          onClose={() => {
            setVisible(false)
            setError('')
          }}
        >
          {error}
        </Alert>
      )}
      {children}
    </AlertContext.Provider>
  )
}
