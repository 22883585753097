import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import * as React from 'react'
import { SxProps } from '@mui/material'

interface CopyrightProps extends React.HTMLAttributes<HTMLDivElement> {
  sx?: SxProps
}

export const Copyright: React.FC<CopyrightProps> = ({ sx, ...other }) => {
  return (
    <Typography variant='body2' color='text.secondary' align='center' sx={sx} {...other}>
      {'Copyright © '}
      <Link color='inherit' href='https://lakioo.com/'>
        LAKIOO
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
