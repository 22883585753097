import { useNavigate } from 'react-router-dom'

import React from 'react'
import Button from '@mui/material/Button'
import API from '../api/API'
import { CONFIG } from '../Config'

export const Users = () => {
  const navigate = useNavigate()
  return (
    <div>
      Users:
      <Button
        onClick={() => {
          API.logOut()
          navigate(CONFIG.ROUTES.LOGIN, { replace: true })
        }}
      >
        logout
      </Button>
    </div>
  )
}
