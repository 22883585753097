import Grid from '@mui/material/Grid'
import CssBaseline from '@mui/material/CssBaseline'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import React, { useContext, useEffect, useState } from 'react'
import { ChangePassword } from '../auth/ChangePasswordComponent'
import API from '../api/API'
import { CONFIG } from '../Config'
import { useNavigate } from 'react-router-dom'
import { AlertContext } from '../error/AlertProvider'

export const HomePage = () => {
  const [timestamp, setTimestamp] = useState(0)
  const navigate = useNavigate()
  const loggedUser = localStorage.getItem('logged')
  const { setMessage } = useContext(AlertContext)
  const [changePasswordVisible, setChangePasswordVisible] = useState(false)

  const checkStatus = () => {
    if (API.isLogged()) {
      API.checkIsLogged()
        .then(resp => {
          console.log(resp.message)
          navigate('/', { replace: true })
        })
        .catch(err => {
          console.log(err)
          setMessage(err.message, 'error')
          navigate('/signincd ', { replace: true })
        })
    }
    setTimestamp(Date.now())
  }
  useEffect(() => {
    setTimeout(() => checkStatus(), 5000)
  }, [timestamp])
  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url('background.png')`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'white',
          backgroundSize: 'contain',
          backgroundPosition: 'left',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src='logoi.png' width={'500px'}></img>
          <Typography component='h6' variant='h5'>
            Welcome [{loggedUser}]
          </Typography>
          <p>
            You can now login by entering your e-mail and password to{' '}
            <Typography color={'#A50E0E'}>
              <strong>LAKIOO Player</strong> & <strong>LAKIOO Creator </strong>
            </Typography>
            to start using LAKIOO Cloud.
          </p>
          {changePasswordVisible && <ChangePassword />}
          {!changePasswordVisible && (
            <Button
              onClick={() => {
                setChangePasswordVisible(true)
              }}
            >
              Change password
            </Button>
          )}
          <Button
            onClick={() => {
              API.logOut()
              navigate(CONFIG.ROUTES.LOGIN, { replace: true })
            }}
          >
            logout
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
