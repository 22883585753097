import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Copyright } from './CopyrightComponent'
import { FunctionComponent, useContext, useMemo, useState } from 'react'
import API from '../api/API'
import { ISignUpData } from '../types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AlertContext } from '../error/AlertProvider'

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme()

export const SignUp: FunctionComponent<{ email?: string | null; code?: string | null }> = ({ email, code }) => {
  const [signUpData, setSigUpData] = useState<ISignUpData>({})
  const [controlPassword, setControlPassword] = useState<string>('')
  const [controlPasswordPristine, setControlPasswordPristine] = useState<boolean>(true)

  const navigate = useNavigate()
  const { setMessage } = useContext(AlertContext)

  const isResetPassword = () => {
    return email && code
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (email != null && code !== null) {
      API.resetPassword(email as string, code as string, signUpData)
        .then(() => {
          navigate('/signin', { replace: true })
        })
        .catch(err => {
          setMessage(err.message, 'error')
        })
    } else {
      API.signUp(signUpData)
        .then(resp => {
          console.log(resp.message)
          setMessage('Account has been created', 'success')
          navigate('/signin', { replace: true })
        })
        .catch(err => {
          setMessage(err.message, 'error')
        })
    }
  }

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  const passwordsMatch = () => {
    return (signUpData.password === controlPassword && controlPassword !== '') || controlPasswordPristine
  }

  const emailFilled           = !!(email ? email : (signUpData.username || ""));
  const passwordFilled        = !!(signUpData.password);
  const controlPasswordFilled = !!(controlPassword);
  const emailCorrect          = validateEmail(email ? email : (signUpData.username || ""));
  const controlPasswordCorrect= passwordsMatch();

  const disableButton         = !(emailCorrect && controlPasswordCorrect && emailFilled && passwordFilled && controlPasswordFilled);

  return (
    //
    <>
      <Link />
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            {isResetPassword() ? 'New Password' : 'Sign up'}
          </Typography>
          <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              {/*<Grid item xs={12} sm={6}>*/}
              {/*  <TextField*/}
              {/*    autoComplete='given-name'*/}
              {/*    name='firstName'*/}
              {/*    required*/}
              {/*    fullWidth*/}
              {/*    id='firstName'*/}
              {/*    label='First Name'*/}
              {/*    value={signUpData.name}*/}
              {/*    onChange={event => setSigUpData({ ...signUpData, name: event.target.value })}*/}
              {/*    autoFocus*/}
              {/*  />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12} sm={6}>*/}
              {/*  <TextField*/}
              {/*    required*/}
              {/*    fullWidth*/}
              {/*    id='lastName'*/}
              {/*    label='Last Name'*/}
              {/*    name='lastName'*/}
              {/*    autoComplete='family-name'*/}
              {/*    value={signUpData.lastName}*/}
              {/*    onChange={event => setSigUpData({ ...signUpData, lastName: event.target.value })}*/}
              {/*  />*/}
              {/*</Grid>*/}
              <Grid item xs={12}>
                <TextField
                  error={emailFilled && !emailCorrect}
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='email'
                  value={email ? email : signUpData.username}
                  onChange={event => setSigUpData({ ...signUpData, username: event.target.value })}
                  inputProps={{ type: "email", readOnly: email !== undefined }}
                  helperText={(!emailFilled || validateEmail(email ? email : (signUpData.username || ""))) ? '' : `Not a valid e-mail`}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={signUpData.password === ''}
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  autoComplete='new-password'
                  value={signUpData.password}
                  onChange={event => setSigUpData({ ...signUpData, password: event.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!controlPasswordCorrect}
                  required
                  fullWidth
                  name='controllPassword'
                  label='Repeat Password'
                  type='password'
                  id='controll-password'
                  autoComplete='new-password'
                  value={controlPassword}
                  onChange={event => {setControlPassword(event.target.value); setControlPasswordPristine(false);}}
                  helperText={passwordsMatch() ? '' : `Passwords don't match`}
                />
              </Grid>
            </Grid>
            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} disabled={disableButton}>
              {isResetPassword() ? 'Set New Password' : 'Sign up'}
            </Button>
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Link
                  href='#'
                  variant='body2'
                  onClick={() => {
                    navigate('/signin', { replace: true })
                  }}
                >
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </>
  )
}
