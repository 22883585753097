const API_URL = '/api/'
export const CONFIG = {
  ROUTES: {
    HOME: '/',
    LOGIN: '/login',
    SETTINGS: '/settings',
  },
  API: {
    LOGIN: API_URL + 'login',
    SIGN_UP: API_URL + 'signup',
    LOGOUT: API_URL + 'logout',
    LOGIN_STATUS: API_URL + 'status',
    PASSWORD: API_URL + 'password',
    RESET_PASSWORD: API_URL + 'password/reset',
    RESET_EMAIL: API_URL + 'reset_password',
    STATUS: API_URL + 'status',
  },
}
