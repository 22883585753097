import { CONFIG } from '../Config'
import { ISignUpData } from '../types'

class Api {
  logged: boolean

  constructor(setLogged: boolean) {
    this.logged = setLogged
  }

  login(loginData: {
    username: string
    password: string
  }): Promise<{ username: string; status: string; isAdmin?: boolean }> {
    return this.post(CONFIG.API.LOGIN, loginData).then(resp => {
      if (resp.status) {
        this.logged = true
        localStorage.setItem('logged', resp.username)
      }
      return resp
    })
  }

  async get(url: string) {
    const response = await fetch(url, { credentials: 'include' })

    this.checkLogged(response)

    if (response.ok) {
      return response.json()
    } else {
      const resp = await response.json()
      throw Error(resp.message ? resp.message : resp)
    }
  }

  async post(url: string, body: any) {
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    this.checkLogged(response)

    if (response.ok) {
      return response.json()
    } else {
      const resp = await response.json()
      throw Error(resp.message ? resp.message : resp)
    }
  }

  checkLogged(response: Response) {
    if (response.status === 401 || response.status === 404) {
      localStorage.removeItem('logged')
      this.logged = false
    }
  }
  signUp(data: ISignUpData) {
    return this.post(CONFIG.API.SIGN_UP, data)
  }

  changePassword(oldPassword: string, newPassword: string) {
    const username = localStorage.getItem('logged')
    if (!username) {
      throw new Error('Not logged in')
    }
    return this.post(CONFIG.API.PASSWORD, { username: username, oldPassword: oldPassword, newPassword: newPassword })
  }

  isLogged(): boolean {
    return <boolean>(localStorage.getItem('logged') && localStorage.getItem('logged') !== '')
  }
  checkIsLogged() {
    return this.get(CONFIG.API.STATUS)
  }

  logOut() {
    localStorage.removeItem('logged')
    this.post(CONFIG.API.LOGOUT, {})
  }

  resetPassword(email: string, code: string, signUpData: ISignUpData) {
    return this.post(CONFIG.API.RESET_PASSWORD, {
      username: email,
      resetCode: code,
      newPassword: signUpData.password,
    })
  }

  sendResetEmail(email: string) {
    return this.post(CONFIG.API.RESET_EMAIL, {
      username: email,
    })
  }
}

export default new Api(false)
